@font-face {
    font-family: "URWGeometric";
    font-weight: 400;
    src: local("URWGeometric"), url('/assets/fonts/URWGeometricRegular.woff2') format("woff2");
}
@font-face {
    font-family: "URWGeometric";
    font-weight: 700;
    src: local("URWGeometric"), url('/assets/fonts/URWGeometricBold.woff2') format("woff2");
}
@font-face {
    font-family: "URWGeometric";
    font-weight: 600;
    src: local("URWGeometric"), url('/assets/fonts/URWGeometricSemiBold.woff2') format("woff2");
}
@font-face {
    font-family: "URWGeometric";
    font-weight: 100;
    src: local("URWGeometric"), url('/assets/fonts/URWGeometricLight.woff2') format("woff2");
}

:root {
    --theme-font: URWGeometric, Helvetica, Arial, sans-serif;
    --theme-font-small-size: 14px;
    --theme-font-standard-size: 15px;
    --theme-font-medium-size: 16px;
    --theme-font-large-size: 17px;
    --theme-font-xlarge-size: 20px;
    --theme-font-button-size: 14px;

    --theme-maturity-levels-chart: url('/assets/diageo-maturity-levels.svg');

    --theme-text: #344054;
    --theme-contrast: #37A1B0;
    --theme-dark-text: #344054;

    --theme-tint: #EFF6FF;
    --theme-light-tint: #EEEFEF;
    --theme-dark-tint: #CBCDD1;
    --theme-heading-tint: #F1F1F2;
    --theme-sidebar-tint: #F3F3F4;

    --theme-colour-tint: #d4eff9;
    --theme-colour-text: #002953;

    --theme-evidence-button: var(--theme-level-1);

    --theme-scored-tint: #FEEDE4;
    --theme-progress-tint: #FFF8EF;

    --theme-level-1: #37A1B0;
    --theme-level-2: #66696D;
    --theme-level-3: #A8AAAC;

    --theme-primary: #C23C3E;
    --theme-secondary: #002953;

    --theme-highlight: #D92E21;
    --theme-progress: #00ABE2;
    --theme-danger: #D2232A;

    --theme-logo-url: url('/assets/diageo-logo.svg');
    --theme-logo-width: 140px;
    --theme-logo-height: 35px;

    --theme-footer-height: 75px;
    --theme-footer-logo-display: none;
    --theme-footer-logo-filter: brightness(0) invert(1);
    --theme-footer-background: var(--theme-tint);
    --theme-footer-text: black;
    --theme-footer-graphic-display: flex;
    --theme-footer-graphic-url: none;
    --theme-footer-graphic-width: 480px;
    --theme-footer-graphic-height: 240px;
    --theme-powered-by-filter: none;

    --theme-nav-image: none;
    --theme-nav-height: 75px;
    --theme-nav-bg: white;
    --theme-nav-selection: #37A1B0;
    --theme-nav-selected-indicator: #37A1B0;
    --theme-checkmark-url: url('/assets/icons/solid-tick-icon.svg');
    --theme-checkmark-top-offset: 0px;
    --theme-checkmark-filter: invert(60%) sepia(71%) saturate(413%) hue-rotate(138deg) brightness(87%) contrast(84%);
    --theme-selected-question-border: none;
    --theme-nav-indicator-size: 50px;
    --theme-nav-indicator-top: 8px;
    --theme-nav-indicator-left: -20px;

    --theme-headings-weight: 600;
    --theme-link-color: #0d6efd;
    --theme-sidebar-height: min-content;
    --theme-unanswered-question-bg: #A9B2BF;
}
