:root {
    --dataset1-background: rgba(74, 189, 207, 0.2);
    --dataset1-border: rgb(74, 189, 207);

    --dataset2-background: rgba(105, 56, 239, 0.2);
    --dataset2-border: rgb(105, 56, 239);

    --dataset3-background: rgba(68, 148, 54, 0.2);
    --dataset3-border: rgb(68, 148, 54);

    --dataset4-background: rgba(221, 37, 144, 0.2);
    --dataset4-border: rgb(221, 37, 144);

    --dataset5-background: rgba(59, 130, 246, 0.2);
    --dataset5-border: rgb(59, 130, 246);

    --dataset6-background: rgba(158, 22, 95, 0.2);
    --dataset6-border: rgb(158, 22, 95);

    --dataset7-background: rgba(108, 233, 166, 0.2);
    --dataset7-border: rgb(108, 233, 166);

    --dataset8-background: rgba(253, 176, 34, 0.2);
    --dataset8-border: rgb(253, 176, 34);

    --dataset9-background: rgba(155, 138, 251, 0.2);
    --dataset9-border: rgb(155, 138, 251);

    --dataset10-background: rgba(240, 68, 56, 0.2);
    --dataset10-border: rgb(240, 68, 56);
}
