img.maturity0 {
    filter: invert(69%) sepia(9%) saturate(93%) hue-rotate(177deg)
        brightness(88%) contrast(87%);
}
img.maturity1 {
    filter: invert(48%) sepia(57%) saturate(2393%) hue-rotate(162deg)
        brightness(91%) contrast(101%);
}
img.maturity2 {
    filter: brightness(0) saturate(100%) invert(42%) sepia(71%) saturate(575%) hue-rotate(327deg) brightness(93%) contrast(89%);
}
img.maturity3 {
    filter: invert(82%) sepia(51%) saturate(2459%) hue-rotate(332deg)
        brightness(103%) contrast(97%);
}
img.maturity4 {
    filter: invert(36%) sepia(22%) saturate(4869%) hue-rotate(134deg)
        brightness(96%) contrast(101%);
}

.maturity0 {
    color: #4c4c4c;
}
.maturity1 {
    color: #00addb;
}
.maturity2 {
    color: #d25d3a;
}
.maturity3 {
    color: #e59649;
}
.maturity4 {
    color: #039345;
}

.maturity0-bg {
    background-color: #f1f1f1!important;
}
.maturity1-bg {
    background-color: #e9f8fb!important;
}
.maturity2-bg {
    background-color: #fcf2ef!important;
}
.maturity3-bg {
    background-color: #fdf6f0!important;
}
.maturity4-bg {
    background-color: #e9f6f0!important;
}
